import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useMemo } from 'react';
import Application from './Application';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <ToastContainer />
      <Application />
    </>
  );
};

export default App;
