import React from "react";
import "./App.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
const Landing = React.lazy(() => import("./Pages/Landing/Landing.tsx"));
const Page404 = React.lazy(() => import("./Pages/404_Page/Page404"));

function Application() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing />,
      errorElement: <Page404 />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default Application;
